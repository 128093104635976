<template>
  <email-change-commit @emailChanged="goto" :emailToken="token" />
</template>

<script>
import EmailChangeCommit from "@/components/settings/EmailChangeCommit";

export default {
  name: "EmailChangeEmail",
  components: {
    EmailChangeCommit,
  },
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  methods: {
    goto() {
      // fix MCF-2120
      // https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
      this.$router.push({ name: "home" }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
