<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("email change from confirm link") }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear :active="loading" indeterminate />
    <v-card-text>
      <v-alert :type="error ? 'error' : 'success'">
        {{ $t(message) }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "EmailChangeCommit",
  props: {
    emailToken: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    error: false,
    message: "email will be changed ...",
  }),
  watch: {
    emailToken: {
      immediate: true,
      handler() {
        this.changeEmail();
      },
    },
  },
  methods: {
    changeEmail: function() {
      var that = this;
      this.loading = true;
      this.$http
        .post("login/change_email", { token: this.emailToken })
        .then(function(response) {
          that.$snotify.success(that.$i18n.t("emailChanged"));
          that.$emit("emailChanged");
          if (that.$store.getters.isReady) {
            // reload profile
            that.$http
              .get("profile", { disableDefaultErrorHandler: true })
              .then((response) => {
                that.$store.commit("setSession", response.data);
              });
            that.$router.push({ name: "home" });
          } else that.$router.push({ name: "login" });
        })
        .catch(function(error) {
          that.message = error.message;
          that.error = true;
        })
        .finally(function() {
          that.loading = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "email change": "email change",
    "email change from confirm link": "email change from confirm link",
    "email will be changed ...": "email will be changed ...",
    "emailChanged": "Your email has been successfully changed."
  },
  "de": {
    "email change": "Email Änderung",
    "email change from confirm link": "Email Änderung über Bestätigungs Link",
    "email will be changed ...": "Email wird geändert ...",
    "emailChanged": "Ihre Email wurde erfolgreich geändert."
  }
}
</i18n>
